<template>
    <div class="payPass">
		<div class="payPassBox">
			<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
			    <el-tab-pane label="初始化交易密码" name="1" v-if="payPass == ''">
					<el-form
						:model="ruleFormCode"
						:rules="rulesCode"
						ref="ruleFormCode"
						label-position="top"
						class="ruleForm"
					>
						<el-form-item label="手机号" prop="urMobile">
						  <el-input v-model="ruleFormCode.urMobile" disabled placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
						  <div style="display: flex;">
						    <div style="flex:1;width:344px;">
						      <el-input v-model="ruleFormCode.code" autocomplete="off" placeholder="请输入手机验证码"></el-input>
						    </div>
						    <div style="margin-left:4px;">
						      <el-button type="primary" :disabled="disableSend" @click="sendBtn">{{sendText}}</el-button>
						    </div>
						  </div>
						</el-form-item>
						<el-form-item label="交易密码" prop="urPayPass">
						  <el-input v-model="ruleFormCode.urPayPass" type="password" placeholder="请输入交易密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="comPass">
						  <el-input v-model="ruleFormCode.comPass" type="password" placeholder="请输入确认密码"></el-input>
						</el-form-item>
						<el-form-item>
						  <el-button type="primary" @click="submitForm('ruleFormCode')" round style="width:100%" :loading="loading">设置</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			    <el-tab-pane label="修改交易密码" name="2" v-if="payPass != ''">
					<el-form
						:model="ruleFormPass"
						:rules="rulesPass"
						ref="ruleFormPass"
						label-position="top"
						class="ruleFormPass"
					>
						<el-form-item label="手机号" prop="urMobile">
						  <el-input v-model="ruleFormPass.urMobile" disabled placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
						  <div style="display: flex;">
						    <div style="flex:1;width:344px;">
						      <el-input v-model="ruleFormPass.code" autocomplete="off" placeholder="请输入手机验证码"></el-input>
						    </div>
						    <div style="margin-left:4px;">
						      <el-button type="primary" :disabled="disableSend" @click="sendBtn">{{sendText}}</el-button>
						    </div>
						  </div>
						</el-form-item>
						<el-form-item label="原密码" prop="oldPass">
						  <el-input v-model="ruleFormPass.oldPass" type="password" placeholder="请输入原密码"></el-input>
						</el-form-item>
						<el-form-item label="交易密码" prop="urPayPass">
						  <el-input v-model="ruleFormPass.urPayPass" type="password" placeholder="请输入交易密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="comPass">
						  <el-input v-model="ruleFormPass.comPass" type="password" placeholder="请输入确认密码"></el-input>
						</el-form-item>
						<el-form-item>
						  <el-button type="primary" @click="submitFormPass('ruleFormPass')" round style="width:100%" :loading="loading">设置</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="找回交易密码" name="3" v-if="payPass != ''">
					暂未开放
				</el-tab-pane>
			</el-tabs>
		</div>
    </div>
	<el-dialog
	    v-model="dialogVisible"
	    title="提示"
	    width="30%"
	    :before-close="handleClose"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
		:show-close="false"
	  >
	    <div style="font-size:16px;font-weight:bold;margin-bottom:10px;color:red;">请仔细阅读，这对您很重要！！！</div>
		<div>
			这是您支付密码的
			“<font style="color:red;font-weight:bold;">助记简词</font>”，
			当您忘记支付密码的时候，可以使用它进行“<font style="color:red;font-weight:bold;">支付密码恢复</font>”！
			出于安全考虑，系统不会保存您的助记简词信息。请牢记这些单词,并把它妥善保存在安全的地方！您可以点击按钮进行复制。
		</div>
		<div style="font-weight:bold;font-size:16px;margin-top:10px;color:red;">
			<table class="box">
				<tr v-for="(item,index) in msg">
					<td v-for="(subItem,subIndex) in item">{{subItem}}</td>
				</tr>
			</table>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button type="primary" @click="knowBtn">知道了</el-button>
	      </span>
	    </template>
	  </el-dialog>
</template>
<script>
export default {
	data() {
		const validatePass = (rule,value, callback) => {
			if(!(/((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,16}/i.test(value))){ 
				callback(new Error('密码格式为字母、数字和字符串组合'))
			} else {
				callback()
			}
		}
		const validateComPass = (rule,value, callback) => {
			let urPassword = this.ruleFormCode.urPayPass;
			if(urPassword != value){
				callback(new Error('两次密码输入不一致'))
			}else{
				callback()
			}
		}
		const validatePassCode = (rule,value, callback) => {
			if(!(/((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,16}/i.test(value))){ 
				callback(new Error('密码格式为字母、数字和字符串组合'))
			} else {
				callback()
			}
		}
		const validateComPassCode = (rule,value, callback) => {
			let urPassword = this.ruleFormPass.urPayPass;
			if(urPassword != value){
				callback(new Error('两次密码输入不一致'))
			}else{
				callback()
			}
		}
		return{
			disableSend:false,
			sendText:'发送验证码',
			sendTime: 120,
			timer: null,
			loading:false,
			dialogVisible:false,
			showUpdatePass:false,
			showInitPass:false,
			msg:[],
			activeName:'1',
			msg_0:'',
			msg_1:'',
			msg_2:'',
			mnemonic:'',
			payPass:'',
			ruleFormPass:{
				urMobile: '',
				code:'',
				urPayPass:'',
				oldPass:'',
				comPass:'',
				urId:window.localStorage.getItem("urId")
				
			},
			ruleFormCode:{
				urMobile: '',
				code:'',
				urPayPass:'',
				
			},
			rulesPass:{
				code:[
					{ required: true, message: '手机验证码不能为空', trigger: 'change' }
				],
				urPayPass:[
					{ required: true, validator: validatePassCode, trigger: 'change' }
				],
				oldPass:[
					{ required: true, message: '原不能为空', trigger: 'change' }
				],
				comPass:[
					{ required: true, validator: validateComPassCode, trigger: 'change' }
				],
			},
			rulesCode:{
				code:[
					{ required: true, message: '手机验证码不能为空', trigger: 'change' }
				],
				urPayPass: [
					{ required: true, validator: validatePass, trigger: 'change' }
				],
				comPass: [
					{ required: true, validator: validateComPass, trigger: 'change' }
				],
			},
		}
	},
	created(){
		this.getObject();
	},
	methods:{
		submitFormPass(ruleFormPass){
			let that = this;
			that.$refs[ruleFormPass].validate((valid) => {
				if (valid) {
					that.loading = true;
					this.$axios({
						method:'post',
						headers: {
							token: window.localStorage.getItem("token")
						},
						url:'user/ur/setPayPass',
						data:this.$qs.stringify(that.ruleFormPass)
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg == 2){
							that.$message.success("保存成功");
							that.getObject();
							that.ruleFormPass={
								urMobile: '',
								code:'',
								urPayPass:'',
								oldPass:'',
								comPass:'',
								urId:window.localStorage.getItem("urId")
								
							}
						}else{
							that.$message.error(msg);
						}
						that.loading = false;
					})
					.catch((err)=>console.log('请求失败',err))
				} else {
					return false;
				}
			});
		},
		submitForm(formName){//初始密码
			let that = this;
			that.$refs[formName].validate((valid) => {
				if (valid) {
					let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
					if(!reg.test(that.ruleFormCode.urMobile)){ 
						that.$message.error('请输入正确的手机号码');
						return false;
					}
					if(that.ruleFormCode.code == ""){
						that.$message.error('请输入手机验证码');
						return false;
					}
					that.loading = true;
					this.$axios({
						method:'post',
						headers: {
							token: window.localStorage.getItem("token")
						},
						url:'user/ur/initPayPass',
						data:this.$qs.stringify({
							urMobile: that.ruleFormCode.urMobile,
							code: that.ruleFormCode.code,
							urPayPass: that.ruleFormCode.urPayPass,
							urId: window.localStorage.getItem("urId")
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg == 2){
							let mnemonic = JSON.parse(res.data.mnemonic);
							let msg = that.getNewArray(mnemonic,4);
							that.mnemonic = mnemonic.join(",").replace(new RegExp("\\,","g")," ");
							that.msg = msg;
							that.dialogVisible = true;
						}else{
							that.loading = false;
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				} else {
					return false;
				}
			});
		},
		knowBtn(){
			let that = this;
			this.$confirm('请确认您已经妥善保存您的"助记简词"!', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				that.$copyText(that.mnemonic).then(() => {
					that.$message.success("复制成功 ！");
				})
				that.ruleFormCode = {
					urMobile: '',
					code:'',
					urPayPass:'',
					
				}
				that.dialogVisible = false;
				that.loading = false;
				that.getObject();
			})
			.catch(() => {});
		},
		sendBtn(){
			let that =this;
			this.$axios({
				method:'post',
				headers: {
					token: window.localStorage.getItem("token")
				},
				url:'sys/ce/sendPayPass',
				data:this.$qs.stringify({
					urMobile: that.ruleFormCode.urMobile,
					urId: window.localStorage.getItem("urId")
				})
			})
			.then((res)=>{
				let msg = res.data.msg;
				if(msg==2){
					that.$message.success("验证码发送成功，120秒内有效，请注意查收！");
					that.startCounter();
				}else{
					that.$message.error(msg);
				}
			})
			.catch((err)=>console.log('请求失败',err))
		},
		startCounter(){
			let that = this;
			that.disableSend = true;
			that.timer = setInterval(()=> {
				that.sendTime --;
				that.sendText = this.sendTime+"秒后可重新发送";
				if(that.sendTime <= 0 ) {
					that.disableSend = false;
					clearInterval(that.timer);
					that.sendTime = 120;
					that.sendText = "发送验证码";
				}
			},1000);
		},
		getObject(){
			let that =this;
			this.$axios({
				method:'post',
				headers: {
					token: window.localStorage.getItem("token")
				},
				url:'user/ur/getObject',
				data:this.$qs.stringify({
					urId: window.localStorage.getItem("urId")
				})
			})
			.then((res)=>{
				this.ruleFormCode.urMobile = res.data.data.urMobile;
				this.ruleFormPass.urMobile = res.data.data.urMobile;
				this.payPass = res.data.data.urPayPass;
				if(this.payPass == ""){
					this.activeName = "1"
				}else{
					this.activeName = "2"
				}
			})
			.catch((err)=>console.log('请求失败',err))
		},
		getNewArray(array,subGroupLength) {
		  let index = 0;
		  let newArray = [];
		  while(index < array.length) {
		      newArray.push(array.slice(index, index += subGroupLength));
		  }
		  return newArray;
		}
	}
};
</script>
<style scoped>
	.payPassBox{
		width:550px;
		padding:15px;
	}
	.payPassBox .el-input{
		height:40px;
	}
	.payPassBox .el-button{
		height:40px;
	}
	.box td{
		padding:10px;
		text-align: left;
	}
</style>
